/* Style title container */
.title__container {
  margin: 13rem 0 17rem 0;
}

/* Style intro title */
.title {
  font-size: 3em;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

/* Set waving animation for emoji */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

/* Keyframe animation */
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* Style intro button */
.intro__link {
  background-color: white;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-radius: 5px;
  border-color: #ffffff;
  outline: none;
  font-family: "Lato", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: #113969;
  box-shadow: 0 0px 4px 1px #113969;
  transition: all 0.3s ease-in-out;
  width: 10.5rem;
  text-decoration: none;
  margin-top: 1rem;
}

/* Create the hidden pseudo-element */
/* include the shadow for the end state */
.intro__link::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* Scale up the button */
.intro__link:hover {
  transform: scale(1.01, 1.01);
  box-shadow: 0 10px 20px -5px #113969;
}

/* Fade in the pseudo-element with the bigger shadow */
.intro__link:hover::after {
  opacity: 1;
}
