@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=Share+Tech&display=swap");

/* Style entire website */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Changes text-highlight colour */
::selection {
  background: #f8ba45;
}

/* Style font for body */
body {
  font-family: "Lato", sans-serif;
}

/* Add padding to h2*/
h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2em;
}

/* Style header used for navigation */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 19.5rem 1rem 19.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Position links */
.header__link {
  float: left;
  color: black;
  text-align: center;
  margin: 12px;
  text-decoration: none;
  font-size: 1em;
  line-height: 25px;
  cursor: pointer;
}

/* Style header links */
.header__link:hover {
  margin-bottom: 2px;
  border-bottom: 2px solid #f8ba45;
  color: #113969;
  transform: scale(1.05, 1.05);
}

/* Style header logo  */
.logo {
  float: left;
  color: black;
  text-align: center;
  margin: 12px;
  text-decoration: none;
  font-size: 1em;
  line-height: 25px;
  cursor: pointer;
  font-size: 1.5em;
  color: #113969;
}

/* Setting container size */
.container {
  margin: auto 20rem auto 20rem;
  font-size: 1.2em;
  word-wrap: normal;
}

/* Style about a tags */
.about__link {
  color: #113969;
}

/* Adding margin for post */
.post {
  margin: 10px auto 10px auto;
}

/* Style footer */
footer {
  margin: 10rem auto 10rem auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #113969;
}

/* Style footer font size */
footer p {
  font-size: 1.2em;
}

/* Style footer icon */
.footer-icon {
  margin: 10px 5px auto 5px;
  color: #113969;
  font-size: 1.3em;
}

/* Style footer icon hover */
.footer-icon:hover {
  transform: scale(1.2, 1.2);
  color: #2d67ae;
}

/* width */
::-webkit-scrollbar {
  width: 1em;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2em;
  border: 4.5px solid transparent;
  background-clip: content-box;
  background-color: #113969;
}

/* Handle */
::-webkit-scrollbar-thumb:hover {
  border-radius: 2em;
  border: 4.5px solid transparent;
  background-clip: content-box;
  background-color: #2d67ae;
}

/* Adjusts margin based on window size (max-width of 1500px) */
@media only screen and (max-width: 1500px) {
  .container {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

/* Adjusts margin based on window size (max-width of 1000px) */
@media only screen and (max-width: 1000px) {
  .container {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

/* Adjusts margin based on window size (max-width of 700px) */
@media only screen and (max-width: 700px) {
  .container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
