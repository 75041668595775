/* Style accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #ffffff;
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

/* Create the hidden pseudo-element */
/* include the shadow for the end state */
.accordion__section::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* Scale up the box */
.accordion__section:hover {
  transform: scale(1.01, 1.01);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3);
}

/* Fade in the pseudo-element with the bigger shadow */
.accordion__section:hover::after {
  opacity: 1;
}

/* Style buttons used to open and close accordion panel */
.accordion {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.25s ease;
}

/* Add a background color to the button if it is clicked on  */
.accordion:hover,
.active {
  background-color: rgb(255, 255, 255);
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  text-align: left;
}

/* Style accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.25s ease;
  color: #113969;
  font-size: 1.3em;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.25s ease;
}

/* Style the accordion content date */
.accordion__date {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
  padding: 10px 18px 10px 18px;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 10px 18px 10px 18px;
}

/* Style the accordion content text container*/
.accordion__tech__container {
  margin-left: 18px;
  margin-top: 10px;
}

/* Style the accordion content text */
.accordion__tech {
  font-family: "Share Tech", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
  padding: 5px;
  background-color: #2d67ae;
  margin-right: 5px;
  border-radius: 2px;
}

/* Style accordion links container */
.accordion__links__container {
  margin-left: 18px;
  margin-bottom: 10px;
}

/* Style accordion links */
.accordion__links {
  margin-right: 0.5rem;
  color: #113969;
  font-size: 1.3em;
}

/* Style accordion links hover */
.accordion__links:hover {
  color: #2d67ae;
}
